import React from 'react';
import { motion } from 'framer-motion';
import servicesImage from '../image/services.jpeg';
import { Link } from 'react-router-dom';

const PopupWindow = ({ isOpen, onClose }) => {
  const modalVariants = {
    hidden: { opacity: 0, y: "-100vh" },
    visible: { opacity: 1, y: "0" },
    exit: { opacity: 0, y: "100vh" },
  };

  const modalStyle = {
    maxWidth: "500px",
    margin: "auto",
    borderRadius: "5px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Add shadow for depth
    overflow: "hidden", // Ensure content doesn't overflow
  };

  return (
    <>
      {isOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={onClose}></div>
          <div className="modal-content" style={modalStyle}>
            <motion.div
              className="box"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <img src={servicesImage} alt="Services" style={{ width: '100%', borderRadius: '5px' }} />

              <div className="buttons is-centered" style={{ marginTop: '20px' }}>
                <Link to="/our-services" className="button is-primary" onClick={onClose}> View More</Link>
                <Link to="/upcoming-events" className="button is-primary" onClick={onClose}>Upcoming Events</Link>
                <button className="button is-danger" onClick={onClose}>
                  Close
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupWindow;
