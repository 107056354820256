import React, { useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import '../css/footer.css';

const Footer = () => {
  const [message, setMessage] = useState("");
  const [input, setInput] = useState({ email: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput(prevInput => ({
      ...prevInput,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://generous-kelcey-shah-chains-of-services-3dc27fb8.koyeb.app/api/newsletter/email/upload/', {
        email: input.email,
      });

      if (response.status === 200) {
        setMessage("Newsletter successfully subscribed!");
      } else {
        setMessage("There was a problem with your subscription.");
      }
      setInput({ email: '' });

      document.getElementById("newsletterBtn").style.display = "none";
    } catch (err) {
      console.error(err);
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <footer style={{ padding: "0", marginTop: "20px" }}>
      <div className="footer" style={{ backgroundColor: "#30475E", padding: "0" }}>
        <div className="about content">
          <h2>Our Location</h2>
          <address>
            8/1 2nd floor, Moti Tabela,<br />
            Near Collectorate office, Indore,<br />
            Madhya Pradesh, India<br />
            afrsciences@gmail.com<br />
            Mob No: 99266 92487<br /><br />
            <a href="//www.dmca.com/Protection/Status.aspx?ID=ddb58975-5747-4c63-8c14-7042294dbb76" title="DMCA.com Protection Status" className="dmca-badge">
              <img src="https://images.dmca.com/Badges/dmca_protected_1_120.png?ID=ddb58975-5747-4c63-8c14-7042294dbb76" alt="DMCA.com Protection Status" />
            </a>
          </address>
          <div className="social">
            <a href="https://www.facebook.com/groups/246702936383719" aria-label="Facebook"><i className="bi bi-facebook" /></a>
            <a href="https://instagram.com/afrsciences" aria-label="Instagram"><i className="bi bi-instagram" /></a>
            <a href="https://www.linkedin.com/company/applied-forensic-research-sciences/" aria-label="LinkedIn"><i className="bi bi-linkedin" /></a>
            <a href="https://www.youtube.com/c/AppliedforensicscienceforjusticeStudentGroup/videos" aria-label="YouTube"><i className="bi bi-youtube" /></a>
            <a href="https://t.me/afsjstudent" aria-label="Telegram"><i className="bi bi-telegram" /></a>
          </div>
        </div>
        <div className="links content">
          <h2>Useful Links</h2>
          <ul>
            <li><Link to="/" className="navbar-item">Home</Link></li>
            <li><a href="https://appliedforensicresearchscience.blogspot.com/" className="navbar-item">Blog</a></li>
            <li><Link to="/achievements" className="navbar-item">About Us</Link></li>
            <li><Link to="/contact-us" className="navbar-item">Contact Us</Link></li>
            <li><Link to="/policies" className="navbar-item">Privacy Policy</Link></li>
          </ul>
        </div>
        <div className="links content">
          <h2>What We Offer</h2>
          <ul>
            <li><a href="https://www.youtube.com/channel/UCXvAKj7xDgXaBPOkG-wJKiw" className="navbar-item" target="_blank" rel="noreferrer">Online Videos</a></li>
            <li><Link to="/upcoming-events" className="navbar-item">Events</Link></li>
            <li><Link to="/mcqs" className="navbar-item">MCQs</Link></li>
            <li><Link to="/competitions" className="navbar-item">Competitions</Link></li>
          </ul>
        </div>
        <div className="newsletter content">
          <h2>Our Newsletter</h2>
          <p>Always Stay Updated</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={input.email}
              onChange={handleChange}
              placeholder="username@gmail.com"
              pattern=".+@gmail\.com"
              title="Please provide only a Gmail address"
              required
            />
            <button id="newsletterBtn" type="submit">Send</button>
            <div className="message">{message && <p>{message}</p>}</div>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
