import React from "react";
import './Loading.css';
import loaderImg from '../image/loader.gif';
const Loading = () => {
  return (
    <div className="loading-overlay">
      <img
        src={loaderImg}
        alt="Logo"
      />
    </div>
  );
};

export default Loading;
