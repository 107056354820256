// src/components/RouteUrls.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from './fixed components/Loading';
import { motion, useScroll, useSpring } from "framer-motion";

import Navbar from './fixed components/Navbar';
import FooterPage from './fixed components/FooterPage';
import NotFoundPage from './fixed components/NotFoundPage';
import MaintenancePage from './fixed components/MaintenancePage';

const Home = lazy(() =>
  new Promise(resolve => {
    setTimeout(() => resolve(import('./home')), 2000); // 2 seconds delay
  })
);

const Achievements = lazy(() => import('./aboutus/Achievements'));
const AdvisorCommittee = lazy(() => import('./aboutus/AdvisorCommittee'));
const Founders = lazy(() => import('./aboutus/Founders'));
const Members = lazy(() => import('./aboutus/Members'));
const Volunteers = lazy(() => import('./aboutus/Volunteers'));

const OurServices = lazy(() => import('./aboutus/OurServices'));
const OngoingEvents = lazy(() => import('./events/OngoingEvents'));
const SuccessfulEvents = lazy(() => import('./events/SuccessfulEvents'));
const UpcomingEvents = lazy(() => import('./events/UpcomingEvents'));

const Articles = lazy(() => import('./study contents/Articles'));
const Mcqs = lazy(() => import('./study contents/Mcq'));
const ResearchPapers = lazy(() => import('./study contents/ResearchPapers'));
const McqTest = lazy(() => import('./fixed components/McqTest'));
const Store = lazy(() => import('./fixed components/Store'));
const Elibrary = lazy(() => import('./study contents/Elibrary'));
const ElibCat = lazy(() => import('./study contents/ElibCat'));

const ContactUs = lazy(() => import('./ContactUs'));
const Policies = lazy(() => import('./fixed components/Policies'));
const ArticlePage = lazy(() => import('./fixed components/ArticlePage'));

function RouteUrls({ children }) {
  const isMaintenance = false; // Toggle this flag based on your maintenance status

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  return (
    <BrowserRouter>
      {isMaintenance ? (
        <Routes>
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
      ) : (
        <>
          <motion.div className="progress-bar" style={{ scaleX }} />

          <Navbar />
          {children}
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />

              <Route path="/achievements" element={<Achievements />} />
              <Route path="/advisor-committee" element={<AdvisorCommittee />} />
              <Route path="/founders" element={<Founders />} />
              <Route path="/members" element={<Members />} />
              <Route path="/volunteers" element={<Volunteers />} />

              <Route path="/our-services" element={<OurServices />} />
              <Route path="/ongoing-events" element={<OngoingEvents />} />
              <Route path="/successful-events" element={<SuccessfulEvents />} />
              <Route path="/upcoming-events" element={<UpcomingEvents />} />

              <Route path="/Mcqs" element={<Mcqs />} />
              <Route path="/McqTest/:id" element={<McqTest />} />
              <Route path="/researchPapers" element={<ResearchPapers />} />
              <Route path="/ArticlePage/:id" element={<ArticlePage />} />
              <Route path="/eLibrary" element={<Elibrary />} />
              <Route path="/e/lib/category/catKey/:catValue" element={<ElibCat />} />

              <Route path="/Store" element={<Store />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/policies" element={<Policies />} />
            </Routes>
          </Suspense>
          <FooterPage />
        </>
      )}
    </BrowserRouter>
  );
}

export default RouteUrls;
