import React from 'react';
import { Link } from 'react-router-dom';
import notFound from '../image/study material/404.jpg';
import { motion } from 'framer-motion';

function NotFoundPage() {
  return (
    <motion.div
      className="container"
      style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <img src={notFound} alt="404 Not Found" style={{ maxWidth: "100%", height: "auto", marginBottom: "20px" }} />
      <h1 className="fs-1" style={{ textAlign: "center", fontFamily: "fantasy", color: "red" }}>
        Oh! Looks Like You Forgot Your Destination
      </h1>
      <Link to="/home">
        <button className="btn btn-outline-success my-3" style={{ width: "100px" }}>
          HOME
        </button>
      </Link>
    </motion.div>
  );
}

export default NotFoundPage;
